import React, { useState } from 'react'

interface Props {
    children: any
}

interface IVar {
    contactIsOpen: boolean
    plainPos: Object
    openBlock: string | null
    hoverText: string | null
}

interface IFunc {
    setContactIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    setPlainPos: React.Dispatch<React.SetStateAction<Object>>
    setOpenBlock: React.Dispatch<React.SetStateAction<null>>
    setHoverText: React.Dispatch<React.SetStateAction<null>>
}

export const InteractionContext = React.createContext({})

export const InteractionProvider = ({ children }: Props) => {
    const [contactIsOpen, setContactIsOpen] = useState(false)
    const [plainPos, setPlainPos] = useState({})
    const [openBlock, setOpenBlock] = useState(null)
    const [hoverText, setHoverText] = useState(null)

    const variables: IVar = {
        contactIsOpen: contactIsOpen,
        plainPos: plainPos,
        openBlock: openBlock,
        hoverText: hoverText,
    }

    const functions: IFunc = {
        setContactIsOpen: setContactIsOpen,
        setPlainPos: setPlainPos,
        setOpenBlock: setOpenBlock,
        setHoverText: setHoverText,
    }

    return (
        <InteractionContext.Provider value={{ ...variables, ...functions }}>
            {children}
        </InteractionContext.Provider>
    )
}

export default InteractionContext
