/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/css/index.css'
import './src/fonts/fonts.css'
import '@fontsource/ibm-plex-sans/300.css'
import '@fontsource/ibm-plex-sans/500.css'

import React from 'react'
import { InteractionProvider } from './src/context/interactionContext'

export const wrapRootElement = ({ element }) => {
    return <InteractionProvider>{element}</InteractionProvider>
}
